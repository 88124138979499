
.modal-backlog  {
  margin-top: 50px;
  width: 100%;
  border-radius: 20px;
}

.js .modal-backlog {
  display: none;
}

.js .modal-backlog.active {
  display: block;
  margin-top: 0;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: .3s;
  animation-name: modal-video;
  transition: opacity .3s ease-out;
  border-radius: 0;
}

.modal {
  margin: 0 auto;
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.modal__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  box-sizing: border-box;
}

.js .modal {
  padding: 0 10px;
}

.js .modal__inner {
  padding: 10px 60px;
}

@media (max-width: 767px) {
  .js .modal__inner {
    padding: 0;
  }
}

.modal__video {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  border-radius: 20px;
}

.js .modal__video {
  animation-timing-function: ease-out;
  animation-duration: .3s;
  animation-name: modal-video-inner;
  transform: translate(0, 0);
  transition: transform .3s ease-out;
}

.modal__close-button {
  position: absolute;
  top: 0;
  right: -10%;
  z-index: 2;
  display: block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: url(../images/icon-close-24.svg) white 50% no-repeat;
}

@media (max-width: 959px) {
  .modal__close-button  {
    right: 0;
    top: -60px;
  }
}

.modal__video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

@keyframes modal-video {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px);
  }
  to {
    transform: translate(0, 0);
  }
}
